import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CartItem } from 'src/app/models/cart-item';
import { Order } from 'src/app/models/order';

@Component({
  selector: 'app-order-detail-list',
  templateUrl: './order-detail-list.component.html',
  styleUrls: ['./order-detail-list.component.scss'],
})
export class OrderDetailListComponent implements OnInit {

  @Input() orders: Order[];
  @Input() startAt: number;
  @Input() minute: number;

  constructor(
    public modalCtrl: ModalController
  ) { }

  ngOnInit() {}

  getItemInCart(itemsInCart: CartItem[]):CartItem{
    return itemsInCart.find(itemInCart=>itemInCart.startAt === this.startAt)
  }

  getFormatHour(minutes:number):{h:string,m:string}{
    return {
      h: Math.floor(minutes / 60) < 10 ? '0' + Math.floor(minutes / 60).toString() : Math.floor(minutes / 60).toString(),
      m: (minutes % 60) < 10 ? '0' + (minutes % 60).toString() : (minutes % 60).toString()
    }
  }

}
