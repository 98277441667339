import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Rol } from 'src/app/models/rol';
import { User } from 'src/app/models/user';
import { AdvisorsService } from 'src/app/services/advisors.service';
import { AuthService } from 'src/app/services/auth.service';
import { RolService } from 'src/app/services/rol.service';

@Component({
  selector: 'app-advisors',
  templateUrl: './advisors.component.html',
  styleUrls: ['./advisors.component.scss'],
})
export class AdvisorsComponent implements OnInit {

  @Input() rolId: number | null;
  @Input() rols?: Rol[] | null;

  private mailFilter$: BehaviorSubject<string|null> = new BehaviorSubject('/')

  advisor$: Observable<User[]>
  myAdvisor$: Observable<User[]>

  searchValue: string = ''

  rol: Rol = {
    businessId: null,
    admin: false,
    owner: false,
    advisor: true,
    uid: null,
  }

  constructor(
    public modalCtrl: ModalController,
    private rolService: RolService,
		private afs: AngularFirestore,
  ) {
    this.advisor$ = combineLatest([
      this.mailFilter$
    ]).pipe(
      switchMap(([mail])=>
        afs.collection<User>('users', ref => {
          let query : firebase.default.firestore.CollectionReference | firebase.default.firestore.Query = ref;
          if (mail) { query = query.where('email', '==', mail) };
          return query
        }).valueChanges()
      )
    );
    this.rol.businessId = this.rolService.rol.businessId
  }

  ngOnInit() {
    this.validateData()
  }

  cancelSearch(){
    this.filterByMail('/')
  }

  filterByMail(mail: string|null) {
    this.mailFilter$.next(mail); 
  }

  getSearchValue(){
    if(this.searchValue === ''){
      this.filterByMail('/')
    }else{
      this.filterByMail(this.searchValue)
    }
  }

  validForm():boolean{
    if (/^[a-zA-Z0-9._-]+([.][a-zA-Z0-9._-]+)*[@][a-zA-Z0-9_-]+([.][a-zA-Z0-9_-]+)*[.][a-zA-Z]{2,5}[ ]?$/.test( this.searchValue ) && this.rols.find(rol=>rol.user.email === this.searchValue) === undefined && this.validateData()){
      return true
    }
    return false
  }

  validateMessage():boolean{
    if(this.rols.find(rol=>rol.user.email === this.searchValue) === undefined){
      return true
    }
    return false
  }

  selectAdvisor(advisor: User){
    this.rol.uid = advisor.uid
  }

  validateData():boolean{
    return Object.entries(this.rol).every(v=>v[1] !== null)
  }

  async onSubmit(){
    this.rol.createAt = Date.now()
    await this.rolService.setDatRol(this.rol)
    await this.modalCtrl.dismiss()
  }
}
