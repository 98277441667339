import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Order } from '../models/order';
import { Schedule } from '../models/schedule';
import { OrderService } from './order.service';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  public schedule$: Observable<Schedule[]>

  public businessIdFilter$: BehaviorSubject<number> = new BehaviorSubject( null );
  public advisorIdFilter$: BehaviorSubject<string> = new BehaviorSubject( null );
  public activeFilter$: BehaviorSubject<boolean> = new BehaviorSubject( null );
  public startAtFilter$: BehaviorSubject<number> = new BehaviorSubject( null );
  public endAtFilter$: BehaviorSubject<number> = new BehaviorSubject( null );
  public orderIdFilter$: BehaviorSubject<number> = new BehaviorSubject( null );

  constructor(
		private afs: AngularFirestore,
    private orderService: OrderService
  ) {
    this.schedule$ = combineLatest([
      this.businessIdFilter$,
      this.advisorIdFilter$,
      this.activeFilter$,
      this.startAtFilter$,
      this.endAtFilter$,
      this.orderIdFilter$
    ]).pipe(
      switchMap(([businessId,advisorId,isActive,startAt,endAt,orderId]) =>
        afs.collection<Schedule>(`schedules`, ref => {
          let query : firebase.default.firestore.CollectionReference | firebase.default.firestore.Query = ref;
          if (businessId) { query = query.where(`businessId`,`==`,businessId) }
          if (advisorId) { query = query.where(`advisorId`,`==`,advisorId) }
          if (isActive) { query = query.where(`active`,`==`,isActive) }

          if (startAt) { query = query.where('startAt', '>=', startAt) };
          if (endAt) { query = query.where('startAt', '<=', endAt) };
          
          if (orderId) { query = query.where(`orderId`,`==`,orderId) }
          return query
        }).valueChanges()
      )
    );
  }

  /* getUserSchedule(uid:string,startAt:number):Observable<Schedule[]>{
    const startI: number[] = getRangeTime(startAt)
    const ref: AngularFirestoreCollection<Schedule> = this.afs.collection<Schedule>(`schedules`,ref => ref.where(`advisorId`,`==`,uid).where(`startAt`,`in`,startI))
    return ref.valueChanges()
  } */

  setSchedule(schedule:Schedule):Promise<void>{
    const ref: AngularFirestoreDocument<Schedule> = this.afs.doc(`schedules/${schedule.startAt}_${schedule.orderId}`);
		return ref.set(schedule, { merge: true })
  }

  updateScheduleActive(schedule:Schedule,active:boolean):Promise<void>{
    const ref: AngularFirestoreDocument<Schedule> = this.afs.doc<Schedule>(`schedules/${schedule.startAt}_${schedule.orderId}`);
    return ref.update({active})
  }

  filterByBusiness(id:number|null){
    this.businessIdFilter$.next(id)
  }

  filterByAdvisor(id:string|null){
    this.advisorIdFilter$.next(id)
  }

  filterByActive(active:boolean|null){
    this.activeFilter$.next(active)
  }

  filterByOrder(id:number|null){
    this.orderIdFilter$.next(id)
  }

  filterByStartAt(startAt: number|null) {
    this.startAtFilter$.next(startAt); 
  }

  filterByEndAt(endAt: number|null) {
    this.endAtFilter$.next(endAt); 
  }

  joinOrders(observable$:Observable<Schedule[]>):Observable<Schedule[]> {
    let data: Schedule[];
    const joinKeys = {};
    return observable$.pipe(
      switchMap((r:Schedule[])=>{
        data = r;
        const oids = Array.from(new Set(data?.map(v => v.orderId)))
        const docs = oids.map(id=>{
          return this.orderService.getOrderById(id)
        })
        return docs.length ? combineLatest(docs) : of([])
      }),
      map(docs=>{
        docs.forEach(v => (joinKeys[(<Order>v).createAt] = v));
        data = data?.map(v => {
          return {...v, order: joinKeys[v.orderId]};
        });
        return data
      })
    );
  }
}
