import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { Business, businessGeo } from '../models/business';
import { AngularFirestore, AngularFirestoreDocument, DocumentReference } from '@angular/fire/compat/firestore';
import * as firebase from 'firebase/compat/app';
import 'firebase/firestore'
import { Rol } from '../models/rol';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {

  public selectedBusines: Business
  
  private idFilter$: BehaviorSubject<number|null> = new BehaviorSubject(2);

  constructor(
		private afs: AngularFirestore,
  ) {
    combineLatest([
      this.idFilter$
    ]).pipe(
      switchMap(([id]) => 
        afs.collection<Business>('business', ref => {
          let query : firebase.default.firestore.CollectionReference | firebase.default.firestore.Query = ref;
          if (id) { query = query.where('createAt', '==', id) };
          return query;
        }).valueChanges()
      )
    ).subscribe(business=> (business.length > 0) ? this.selectedBusines=business[0] : null)
  }

  filterById(id: number|null) {
    this.idFilter$.next(id); 
  }

  getBusinessById(businessId:number):Observable<Business>{
    const ref: AngularFirestoreDocument<Business> = this.afs.doc(`business/${businessId}`)
    return ref.valueChanges()
  }

  setBusiness(business:Business,rol:Rol):Promise<void>{
    const batch = this.afs.firestore.batch();
    const rolRef: DocumentReference<Rol> = this.afs.doc<Rol>(`rols/${rol.businessId}`).ref;
    const businessRef: DocumentReference<Business> = this.afs.doc<Business>(`business/${business.createAt}`).ref;
    batch.set(rolRef,rol,{merge:true})
    batch.set(businessRef,business,{merge:true})
    return batch.commit()
  }
}
