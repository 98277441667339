import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { NavController } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { User } from '../models/user';
import firebase from 'firebase/compat/app';
import 'firebase/auth'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

	user$: Observable<User>;

  constructor(
		private afAuth: AngularFireAuth,
		private afs: AngularFirestore,
		private navCtrl: NavController,
	) {
		this.user$ = this.afAuth.authState.pipe(
			switchMap( user => {
				if(user){
					return this.afs.doc<User>(`users/${user.uid}`).valueChanges()
				}else{
					return of(null)
				}
			})
		)
	}

	async createUserWithEmailAndPassword(email,password,data): Promise<void>{
		const date:number = Date.now()
		const credential = await this.afAuth.createUserWithEmailAndPassword(email,password)
		const user: User = {
			code: this.generateCode(credential.user.email),
			uid: credential.user.uid,
			email: credential.user.email,
			createAt: date,
			photoURL: credential.user.photoURL,
			phone: data.phone,
			firstName: data.firstName.toLowerCase(),
			lastName: data.lastName.toLowerCase(),
			fcmTokens: {},
			updateAt: date,
		}
		const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);
		return userRef.set(user, { merge: true })
	}

	async updateUser(user?: Partial<User>):Promise<void>{
		const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);
		return await userRef.update(user)
	}

	async saveToken(user:User,token:string):Promise<void> {
		const currentTokens = user.fcmTokens || { }
		if (!currentTokens[token]) {
		  const userRef = this.afs.collection('users').doc(user.uid)
		  const tokens = { ...currentTokens, [token]: true }
		  userRef.update({ fcmTokens: tokens })
		}
	}

	async getPromiseUser():Promise<User>{
		return await this.user$.pipe(first()).toPromise();
	}

	async getUserById(uid: string):Promise<User>{
		const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${uid}`)
		return userRef.valueChanges().pipe(first()).toPromise()
	}

	async signInWithEmailAndPassword(email,password): Promise<firebase.auth.UserCredential>{
		/* const credential = */return await this.afAuth.signInWithEmailAndPassword(email,password)
		/* const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${credential.user.uid}`);
		return await userRef.update({ authMethod: 0 }); */
	}

	async updatePassword(newPassword):Promise<void>{
		const user: firebase.User = await this.afAuth.currentUser;
		return await user.updatePassword(newPassword)
	}

	async signOut() {
		await this.afAuth.signOut()
		await this.navCtrl.navigateRoot('/');
	}

	async recoverPass(email: string){
	  return this.afAuth.sendPasswordResetEmail(email)
	}

	async getUserWithCode( code:string ):Promise<User[]>{
		const ref = this.afs.collection<User>('users', ref => ref.where('code','==',code));
		return ref.valueChanges().pipe(first()).toPromise()
	}

	private generateCode(email: string){
		let user = email.substring(0,4)
		return user.toUpperCase()+this.rand_code(4)
	}

	private rand_code(lon){
		let chars = '0123456789'
		let code = ''
		for (let x=0; x < lon; x++){
			let rand = Math.floor(Math.random()*chars.length);
			code += chars.substr(rand, 1);
		}
		return code;
	}
}
