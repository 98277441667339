import { Component, Input, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { CartItem } from 'src/app/models/cart-item';
import { Item } from 'src/app/models/item';
import { Schedule } from 'src/app/models/schedule';
import { User } from 'src/app/models/user';
import { ItemsService } from 'src/app/services/items.service';
import { OrderService } from 'src/app/services/order.service';
import { RolService } from 'src/app/services/rol.service';
import { ScheduleService } from 'src/app/services/schedule.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent implements OnInit {

  @Input() bookingDate: Date;

  items: Item[]

  auxMaxMinutes:number
  auxMaxBookingDate:Date

  loader: HTMLIonLoadingElement;

  constructor(
    private loadingCtrl: LoadingController,
    private itemService: ItemsService,
    private orderService: OrderService,
    public modalCtrl: ModalController,
    private rolService: RolService,
    private scheduleService: ScheduleService
  ) {
  }

  ngOnInit() {
    this.itemService.getActiveItemsBusiness(this.rolService.rol.businessId).subscribe(items=>
      this.items = items
    )
  }

  async selectItem(item:Item){
    this.loader = await this.loadingCtrl.create({
      message: 'Realizando pedido...',
      duration: 2000
    });
    await this.loader.present();
    try {
      const cartItem: CartItem = {
        quantity: 1,
        status: 2,
        advisorID: this.rolService.rol.uid,
        item: item
      }
      this.orderService.myOrder.itemsInCart = [...this.orderService.myOrder.itemsInCart,cartItem]
      this.auxMaxMinutes = 0
      this.orderService.myOrder = {
        userID: '4m0eZEEn64haBB6xIyy6qDcKLP13',
        createAt: Date.now(),
        price: this.getPaymentValue(),
        orderStatus: 2,
        itemsInCart: this.orderService.myOrder.itemsInCart.map((cartItem,i)=>{
          this.auxMaxBookingDate = new Date(this.bookingDate)
          cartItem.startAt = this.bookingDate.getTime()
          this.auxMaxMinutes = this.auxMaxMinutes + cartItem.item.duration
          cartItem.endAt = this.auxMaxBookingDate.setMinutes(this.auxMaxBookingDate.getMinutes() + this.auxMaxMinutes)
          return cartItem
        }),
        rate: {
          rated: false,
          value: null
        },
        businessID: this.rolService.rol.businessId,
        payments: {
          subtotal: this.getTotalCost(),
          iva: this.formatNumber(this.getTotalCost() * .12)
        },
        bookingDate: this.bookingDate.getTime(),
        duration: this.getDuration(),
      }
      this.orderService.myOrder.itemsInCart.forEach(async(v)=>{
        const schedule: Schedule = {
          startAt: this.orderService.myOrder.itemsInCart[0].startAt,
          endAt: this.orderService.myOrder.itemsInCart[0].endAt,
          orderId: this.orderService.myOrder.createAt,
          active: true,
          businessId: this.rolService.rol.businessId,
          advisorId: this.rolService.rol.uid,
          duration: v.item.duration
        }
        await this.scheduleService.setSchedule(schedule)
      })
      await this.orderService.createOrder()
      this.orderService.resetOrder()
      await this.loader.dismiss()
      await this.modalCtrl.dismiss()
    } catch (error) {
      
    }
  }

  getDuration(): number {
    return this.orderService.myOrder.itemsInCart.reduce((a, b) => a + (b.item.duration * b.quantity), 0)
  }

  getPaymentValue(): number {
    return this.formatNumber((this.getTotalCost() + (this.getTotalCost() * .12)))
  }

  getTotalCost(): number {
    return this.orderService.myOrder.itemsInCart.reduce((a, b) => a + (b.item.price * b.quantity), 0)
  }

  private formatNumber(number:number):number{
    return Number(number.toFixed(2))
  }

}
