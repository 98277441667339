import { Day } from "../models/business";

export const DAYS: {[index:number]:Day} = [
    {active: false, open: 480, close: 1080, lunchBreak: false, lunchStart: 780, lunchEnd: 900},
    {active: true, open: 480, close: 1080, lunchBreak: false, lunchStart: 780, lunchEnd: 900},
    {active: true, open: 480, close: 1080, lunchBreak: false, lunchStart: 780, lunchEnd: 900},
    {active: true, open: 480, close: 1080, lunchBreak: false, lunchStart: 780, lunchEnd: 900},
    {active: true, open: 480, close: 1080, lunchBreak: false, lunchStart: 780, lunchEnd: 900},
    {active: true, open: 480, close: 1080, lunchBreak: false, lunchStart: 780, lunchEnd: 900},
    {active: false, open: 480, close: 1080, lunchBreak: false, lunchStart: 780, lunchEnd: 900},
]