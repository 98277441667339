import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ScheduleService } from 'src/app/services/schedule.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {

  selectedTiempo: Date = new Date(this.scheduleService.startAtFilter$.getValue()) 

  constructor(
    public modalCtrl: ModalController,
    public scheduleService: ScheduleService
  ) { }

  ngOnInit() {}

  closeModal(){
    this.selectedTiempo
    this.selectedTiempo.setHours(0,0,0,0)
    this.scheduleService.filterByStartAt(this.selectedTiempo.getTime())
    this.selectedTiempo.setHours(23,59,59,999)
    this.scheduleService.filterByEndAt(this.selectedTiempo.getTime())
    this.modalCtrl.dismiss()
  }

}
