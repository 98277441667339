import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-img',
  templateUrl: './img.component.html',
  styleUrls: ['./img.component.scss'],
})
export class ImgComponent implements OnInit {

  @Input() imgSrc: String;
  @Input() spinnerSrc: String;
  @Input() imgContainerClass: String;
  @Input() border: number;

  loading: boolean = true

  onLoad() {
    this.loading = false;
  }

  constructor() { }

  ngOnInit() { }

}
