import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
/* import { RateComponent } from './rate/rate.component';
import { TypeComponent } from '../modals/type/type.component';
import { LogoComponent } from './logo/logo.component';
import { OrderProfileComponent } from '../modals/order-profile/order-profile.component'; */
import { IonicModule } from '@ionic/angular';
import { AgmCoreModule } from '@agm/core';
/* import { ScheduleComponent } from '../modals/schedule/schedule.component'; */
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
/* import { RateBusinessComponent } from '../modals/rate-business/rate-business.component'; */
import { ImagePicker } from '@awesome-cordova-plugins/image-picker/ngx';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { WebImageComponent } from '../modals/web-image/web-image.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ProductListComponent } from '../modals/product-list/product-list.component';
import { OrderDetailListComponent } from '../modals/order-detail-list/order-detail-list.component';
/* import { BusinessPipe } from '../pipes/business.pipe';
import { LetterBoldPipe } from '../pipes/letter-bold.pipe';
import { ItemsPipe } from '../pipes/items.pipe'; */
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ItemComponent } from '../modals/item/item.component';
import { BusinessComponent } from '../modals/business/business.component';
import { ImgComponent } from './img/img.component';
import { CalendarComponent } from '../modals/calendar/calendar.component';
import { AdvisorsComponent } from '../modals/advisors/advisors.component';

@NgModule({
  declarations: [
    ImgComponent,
    /* RateComponent,
    TypeComponent,
    LogoComponent,
    OrderProfileComponent,
    ScheduleComponent,
    RateBusinessComponent,
    BusinessPipe,
    LetterBoldPipe,
    ItemsPipe */
    OrderDetailListComponent,
    WebImageComponent,
    ItemComponent,
    ProductListComponent,
    BusinessComponent,
    CalendarComponent,
    AdvisorsComponent
  ],
  imports: [
    CommonModule,
    AgmCoreModule,
    IonicModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
    NgxMaterialTimepickerModule
  ],
  exports: [
    ImgComponent,
    /* RateComponent,
    TypeComponent,
    LogoComponent,
    OrderProfileComponent,
    ScheduleComponent, */
    FormsModule,
    AgmCoreModule,
    MatDatepickerModule,
    MatNativeDateModule,
    WebImageComponent,
    ItemComponent,
    OrderDetailListComponent,
    ProductListComponent,
    ImageCropperModule,
    ReactiveFormsModule,
    NgxMaterialTimepickerModule,
    BusinessComponent,
    CalendarComponent,
    AdvisorsComponent
    /* RateBusinessComponent,
    BusinessPipe,
    LetterBoldPipe,
    ItemsPipe */
  ],
  providers: [
    ImagePicker,
    OpenNativeSettings,
  ]
})
export class SharedModule { }
