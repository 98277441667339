import { Component, Input, OnInit } from '@angular/core';
import { ActionSheetController, LoadingController, ModalController, Platform, ToastController } from '@ionic/angular';
import { Geolocation } from '@capacitor/geolocation';
import { Camera, CameraResultType, ImageOptions } from '@capacitor/camera';
import { ImagePicker, ImagePickerOptions } from '@awesome-cordova-plugins/image-picker/ngx';
import { Business } from 'src/app/models/business';
import { DAYS } from 'src/app/mocks/days';
import { GOOGLEMAP } from 'src/app/mocks/googlemap';
import { BusinessService } from 'src/app/services/business.service';
import { Rol } from 'src/app/models/rol';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import firebase from 'firebase/compat/app';
import * as geofire from 'geofire-common';
import 'firebase/firestore';
import { WebImageComponent } from '../web-image/web-image.component';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { AngularFireStorage } from '@angular/fire/compat/storage';

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.scss'],
})
export class BusinessComponent implements OnInit {

  @Input() businessId: number | null;

  cameraOptions: ImageOptions = {
    width: 512,
    quality: 512,
    allowEditing: true,
    resultType: CameraResultType.Uri,
    promptLabelHeader: 'Tomar Fotografía',
    promptLabelCancel: 'Cancelar',
    promptLabelPicture: 'Tomar Fotografía'
  }
  
  imagePickerOptions: ImagePickerOptions = {
    maximumImagesCount: 1,
    width: 512,
    height: 512,
    quality: 80,
    outputType: 0
  }

  business: Business = {
    createAt: null,
    name: null,
    keywords: null,
    type: null,
    status: 0,
    logoImageURL: null,
    coverImageURL: null,
    isOpen: true,
    active: true,
    direction: null,
    g: null,
    phone: null,
    statistics: {
      totalReviews: 0,
      rateReviews: 0
    },
    schedule: {}
  }

  rol: Rol = {
    businessId: null,
    admin: null,
    advisor: null,
    owner: null,
    uid: null,
    createAt: null
  }

  logoImageURL: string;
  coverImageURL: string;

  days = DAYS;
  dayName: string[] = ['Dom','Lun','Mar','Mié','Jue','Vie','Sáb']

  loading: HTMLIonLoadingElement;
  toast: HTMLIonToastElement;

  lat: number
  lng: number

  centerLat: number
  centerLng: number

  styles: google.maps.MapTypeStyle[] = GOOGLEMAP

  advisor: User

  constructor(
    private platform: Platform,
    private toastCtrl: ToastController,
    private loadingCtrl: LoadingController,
    private businesService: BusinessService,
    private authService: AuthService,
    private actionSheetCtrl: ActionSheetController,
    public modalCtrl: ModalController,
    private imagePicker: ImagePicker,
    private openNativeSettings: OpenNativeSettings,
    private storage: AngularFireStorage,
  ) {
    authService.user$.subscribe(advisor=>{
      if(advisor != null){
        this.advisor = advisor
      }
    })
  }

  ngOnInit() {
    this.getLocation()
    if(this.businessId !== null) {
      this.businesService.getBusinessById(this.businessId).subscribe(business=>{
        if(business !== null){
          this.days = business.schedule
          this.business = business
        }
      });
    }
  }

  async getLocation(){
    try{

      if(this.platform.is('cordova')){
        const check = await Geolocation.checkPermissions();
        if(check.coarseLocation === 'granted'){
          const coordinates = await Geolocation.getCurrentPosition()
          this.lat = coordinates.coords.latitude
          this.lng = coordinates.coords.longitude
        }else{
          const perm = await Geolocation.requestPermissions();
          if(perm.coarseLocation === 'granted'){
            const coordinates = await Geolocation.getCurrentPosition()
            this.lat = coordinates.coords.latitude
            this.lng = coordinates.coords.longitude
          }else{
            const toast = await this.toastCtrl.create({
              message: 'Los permisos de navegación son encesarios para registrar un negocio.',
              duration: 2000,
              color: 'danger'
            });
            return await toast.present();
          }
        }
      }else{
        navigator.geolocation.getCurrentPosition(pos=>{
          this.lat = pos.coords.latitude
          this.lng = pos.coords.longitude
        })
      }
    }catch(error){
      const toast = await this.toastCtrl.create({
        message: 'No se ha podido acceder a la geolocalización.',
        duration: 2000,
        color: 'danger'
      });
      return await toast.present();
    }
  }

  newCenter(e){
    this.centerLat = e.lat
    this.centerLng = e.lng
  }

  searchLocation(map){
    map.addListener("dragend", ()=>{
      this.lat = this.centerLat
      this.lng = this.centerLng
    })
  }

  clickMap(e) {
    console.log(e)
  }

  typeChanged(e){
    this.business.type = Number(e.detail.value)
  }

  async onSubmit(){
    this.loading = await this.loadingCtrl.create({
      backdropDismiss: false,
      spinner: 'lines'
    });
    await this.loading.present();
    try {
      if(!this.business.createAt){
        const date: number = Date.now()
        this.business.createAt = date
      }
      if(this.logoImageURL){
        this.business.logoImageURL = await this.uploadImg(this.logoImageURL,'logo')
      }
      if(this.coverImageURL){
        this.business.coverImageURL = await this.uploadImg(this.coverImageURL,'cover')
      }
      this.business.g = {
        geohash: geofire.geohashForLocation([this.lat,this.lng]),
        geopoint: new firebase.firestore.GeoPoint(this.lat,this.lng)
      }
      this.business.schedule = this.days
      this.rol = {
        owner: true,
        admin: true,
        advisor: true,
        businessId: this.business.createAt,
        createAt: this.business.createAt,
        uid: this.advisor.uid
      }
      await this.businesService.setBusiness(this.business,this.rol)
      await this.loading.dismiss();
      this.toast = await this.toastCtrl.create({
        message: `Se ha ${this.businessId ? 'actualizado': 'guardado'} la información con éxito`,
        duration: 3000
      });
      await this.toast.present();
      await this.modalCtrl.dismiss()
    } catch (error) {
      console.log(error)
      await this.loading.dismiss();
      this.toast = await this.toastCtrl.create({
        message: 'No se ha podido actualizar el negocio',
        color: 'warning',
        duration: 2000
      });
      await this.toast.present();
    }
  }

  async presentActionSheet(isLogo:boolean) {
    if(this.platform.is("cordova")){
      const actionSheet = await this.actionSheetCtrl.create({
        header: isLogo ? 'Logotipo' : 'Portada',
        buttons: [
          {
            text: 'Tomar foto',
            icon: 'camera',
            handler: () => {
              this.takePhoto(isLogo)
            }
          },
          {
            text: 'Abrir galería',
            icon: 'folder-open',
            handler: () => {
              this.takeImage(isLogo)
            }
          }
        ]
      });
      await actionSheet.present();
    }else{
      const modal = await this.modalCtrl.create({
        component: WebImageComponent,
        componentProps: {
          maxWidth: isLogo ? 128 : 512,
          title: isLogo ? 'Logotipo' : 'Portada',
          aspectRatio: isLogo ? 1/1 : 2/1,
        }
      });
      await modal.present();
      const { data } = await modal.onWillDismiss();
      if(data?.croppedImage){
        if (isLogo) {
          this.logoImageURL = data?.croppedImage
        }else{
          this.coverImageURL = data?.croppedImage
        }
      }
    }
  }

  async takePhoto(isLogo:boolean){
    try{
      if(await this.imagePicker.hasReadPermission()){
        const imageData = await Camera.getPhoto(this.cameraOptions)
        if(imageData[0]){
          const modal = await this.modalCtrl.create({
            component: WebImageComponent,
            componentProps: {
              maxWidth: isLogo ? 128 : 512,
              title: isLogo ? 'Logotipo' : 'Portada',
              aspectRatio: isLogo ? 1/1 : 2/1,
              imageBase64: 'data:image/jpeg;base64,'+imageData[0]
            }
          });
          await modal.present();
          const { data } = await modal.onWillDismiss();
          if(data?.croppedImage){
            if (isLogo) {
              this.logoImageURL = data?.croppedImage
            }else{
              this.coverImageURL = data?.croppedImage
            }
          }
        }
      }else{
        await this.handlePermission()
      }
    }catch(error){
      const date: number = Date.now()
      this.toast = await this.toastCtrl.create({
        message: 'No se pudo tomar la foto',
        color: 'danger',
        duration: 2000
      });
      await this.toast.present();
    }
  }

  async takeImage(isLogo:boolean){
    try{
      if(await this.imagePicker.hasReadPermission()){
        const imageData = await this.imagePicker.getPictures(this.imagePickerOptions)
        if(imageData[0]){
          const modal = await this.modalCtrl.create({
            component: WebImageComponent,
            componentProps: {
              maxWidth: isLogo ? 128 : 512,
              title: isLogo ? 'Logotipo' : 'Portada',
              aspectRatio: isLogo ? 1/1 : 2/1,
              imageBase64: 'data:image/jpeg;base64,'+imageData[0]
            }
          });
          await modal.present();
          const { data } = await modal.onWillDismiss();
          if(data?.croppedImage){
            if (isLogo) {
              this.logoImageURL = data?.croppedImage
            }else{
              this.coverImageURL = data?.croppedImage
            }
          }
        }
      }else{
        await this.handlePermission()
      }
    }catch(error){
      const date: number = Date.now()
      this.toast = await this.toastCtrl.create({
        message: 'No se pudo seleccionar la imagen',
        color: 'danger',
        duration: 2000
      });
      await this.toast.present();
    }
  }

  private async handlePermission(){
    this.toast = await this.toastCtrl.create({
      message: 'Se necesitan permisos de almacenamiento',
      duration: 4000,
      color: 'warning',
      buttons: [
        {
          side: 'end',
          icon: 'cog',
          text: 'Administrar',
          handler: async () => {
            await this.openNativeSettings.open('application_details')
          }
        }
      ]
    });
    return await this.toast.present();
  }

  async uploadImg(data_url:string,url:string):Promise<string>{
    try{
      const filePath = `assets/images/photos/business/${url}/${this.business.createAt}.jpeg`;
      const ref = this.storage.ref(filePath);
      const task = await ref.putString(data_url,'data_url',{
        contentType: 'image/jpeg',
      });
      return task.ref.getDownloadURL()
    }catch(error){
      const date: number = Date.now()
      this.toast = await this.toastCtrl.create({
        message: 'No se pudo cargar la imagen seleccionada',
        duration: 2000,
        color: 'danger'
      });
      await this.toast.present();
    }
  }

  getTime(time: number) {
    let minutes = time % 60
    let hours = (time - minutes) / 60
    return (hours < 10 ? '0' + hours : hours) + ":" + (minutes < 10 ? '0' + minutes : minutes)
  }

  addTime(i:number,isOpen:boolean):number{
    if(isOpen){
      return this.days[i].open < (this.days[i].close - 30) ? this.days[i].open += 30 : null
    }
    return this.days[i].close < 1140 ? this.days[i].close += 30 : null
  }

  removeTime(i:number,isOpen:boolean):number{
    if(isOpen){
      return this.days[i].open > 0 ? this.days[i].open -= 30 : null
    }
    return this.days[i].close > this.days[i].open + 30 ? this.days[i].close -= 30 : null
  }

  addLunchTime(i:number,isOpen:boolean):number{
    if(isOpen){
      return this.days[i].lunchStart < (this.days[i].lunchEnd - 30) && this.days[i].lunchStart >= (this.days[i].open + 30) ? this.days[i].lunchStart += 30 : null
    }
    return this.days[i].lunchEnd < (this.days[i].close - 30) && this.days[i].lunchEnd >= (this.days[i].lunchStart + 30) ? this.days[i].lunchEnd += 30 : null
  }

  removeLunchTime(i:number,isOpen:boolean):number{
    if(isOpen){
      return this.days[i].lunchStart > this.days[i].open + 30 ? this.days[i].lunchStart -= 30 : null
    }
    return this.days[i].lunchEnd > this.days[i].lunchStart + 30 ? this.days[i].lunchEnd -= 30 : null
  }

  validForm():boolean{
    if(this.business.name && this.business.keywords && (this.logoImageURL || this.business.logoImageURL) && (this.coverImageURL || this.business.coverImageURL) && this.business.phone){
      return true 
    }
    return false
  }

}
