import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { Item } from '../models/item';

@Injectable({
  providedIn: 'root'
})
export class ItemsService {

  constructor(
		private afs: AngularFirestore
  ) { }

  getActiveItemsBusiness(businessId:number):Observable<Item[]>{
    const ref: AngularFirestoreCollection<Item> = this.afs.collection(`items`,ref => ref.where('active','==',true).where('businessId','==',businessId));
    return ref.valueChanges();
  }

  getItemsBusiness(businessId:number):Observable<Item[]>{
    const ref: AngularFirestoreCollection<Item> = this.afs.collection(`items`,ref => ref.where('businessId','==',businessId));
    return ref.valueChanges();
  }

  getItemById(itemId:number):Observable<Item>{
    const ref: AngularFirestoreDocument<Item> = this.afs.doc(`items/${itemId}`)
    return ref.valueChanges()
  }

  setItem(item:Item):Promise<void>{
    const ref: AngularFirestoreDocument<Item> = this.afs.doc(`items/${item.createAt}`)
    return ref.set(item,{merge:true})
  }

  setStatus(item:Item,active:boolean):Promise<void>{
    const ref: AngularFirestoreDocument<Item> = this.afs.doc(`items/${item.createAt}`)
    return ref.update({active})
  }
}
