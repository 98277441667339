import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { Rol } from '../models/rol';
import { Storage, SetOptions } from '@capacitor/storage';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { map, switchMap } from 'rxjs/operators';
import { BusinessService } from './business.service';
import { Business } from '../models/business';
import { AuthService } from './auth.service';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class RolService {

  /* testRol: Rol = {
    admin: true,
    owner: true,
    advisor: true,
    businessId: 1651718788160,
    uid: 'XhBSzDB6KDTLL5oB7tB9eQOIYc53',
    createAt: 1651718788160
  } */

  private rolSubject$: BehaviorSubject<Rol> = new BehaviorSubject( null );
  public rol: Rol = null;

  constructor(
		private afs: AngularFirestore,
    private businessService: BusinessService,
    private authService: AuthService
  ) {
    this.rolSubject$.subscribe(_ => this.rol = _);
  }

  getUserRols(uid:string):Observable<Rol[]>{
    const ref: AngularFirestoreCollection<Rol> = this.afs.collection<Rol>(`rols`,ref => ref.where(`uid`,`==`,uid))
    return ref.valueChanges()
  }

  getBusinessRols(businessId:number):Observable<Rol[]>{
    const ref: AngularFirestoreCollection<Rol> = this.afs.collection<Rol>(`rols`,ref => ref.where(`businessId`,`==`,businessId))
    return ref.valueChanges()
  }

  async getRol(){
    const rol = await this.getStorageData()
    if(rol){
      this.setRol(rol)
    }
  }

  async setRol(rol:Rol){
    await this.setStorageData(rol)
    this.rolSubject$.next( rol );
  }

  async setDatRol(rol:Rol){
    const ref: AngularFirestoreDocument = this.afs.doc<Rol>(`rols/${rol.createAt}`)
    return ref.set(rol,{merge:true})
  }

  private async setStorageData(rol:Rol){
    const data: SetOptions = {
      key: 'rol',
      value: JSON.stringify(rol)
    }
    return await Storage.set(data);
  }

  private async getStorageData():Promise<Rol>{
    const { value } = await Storage.get({ key: 'rol' });
    return JSON.parse(value)
  }

  joinBusiness(rol$:Observable<Rol[]>):Observable<Rol[]> {
    let data: Rol[];
    const joinKeys = {};
    return rol$.pipe(
      switchMap((r:Rol[])=>{
        data = r;
        const ids = Array.from(new Set(data?.map(v => v.businessId)))
        const docs = ids.map(id=>{
          return this.businessService.getBusinessById(id)
        })
        return docs.length ? combineLatest(docs) : of([])
      }),
      map(docs=>{
        docs.forEach(v => (joinKeys[(<Business>v).createAt] = v));
        data = data?.map(v => {
          return {...v, business: joinKeys[v.businessId]};
        });
        return data
      })
    );
  }

  joinUsers(rol$:Observable<Rol[]>):Observable<Rol[]> {
    let data: Rol[];
    const joinKeys = {};
    return rol$.pipe(
      switchMap((r:Rol[])=>{
        data = r;
        const uds = Array.from(new Set(data?.map(v => v.uid)))
        const docs = uds.map(id=>{
          return this.authService.getUserById(id)
        })
        return docs.length ? combineLatest(docs) : of([])
      }),
      map(docs=>{
        docs.forEach(v => (joinKeys[(<User>v).uid] = v));
        data = data?.map(v => {
          return {...v, user: joinKeys[v.uid]};
        });
        return data
      })
    );
  }
}
