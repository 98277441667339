import { Component, Input, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ImagePicker, ImagePickerOptions } from '@awesome-cordova-plugins/image-picker/ngx';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { Camera, CameraResultType, ImageOptions } from '@capacitor/camera';
import { ActionSheetController, LoadingController, ModalController, Platform, ToastController } from '@ionic/angular';
import { Item } from 'src/app/models/item';
import { ItemsService } from 'src/app/services/items.service';
import { RolService } from 'src/app/services/rol.service';
import { WebImageComponent } from '../web-image/web-image.component';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class ItemComponent implements OnInit {

  cameraOptions: ImageOptions = {
    width: 512,
    quality: 80,
    allowEditing: true,
    resultType: CameraResultType.Uri,
    promptLabelHeader: 'Tomar Fotografía',
    promptLabelCancel: 'Cancelar',
    promptLabelPicture: 'Tomar Fotografía'
  }
  
  imagePickerOptions: ImagePickerOptions = {
    maximumImagesCount: 1,
    width: 512,
    height: 512,
    quality: 80,
    outputType: 0
  }

  @Input() itemId?: number

  item:Item = {
    name: null,
    price: null,
    active: true,
    createAt: null,
    description: null,
    businessId: null,
    discount: 0,
    duration: 15,
    coverImgURL: null,
    updateAt: null
  }

  coverImgURL: string;

  loading: HTMLIonLoadingElement;
  toast: HTMLIonToastElement;

  photosToUpload: string[] = [];

  constructor(
    private itemService: ItemsService,
    public modalCtrl: ModalController,
    private platform: Platform,
    private storage: AngularFireStorage,
    private openNativeSettings: OpenNativeSettings,
    private actionSheetCtrl: ActionSheetController,
    private imagePicker: ImagePicker,
    private loadingCtrl: LoadingController,
    private toastCtrl: ToastController,
    private rolService: RolService
  ) {
  }

  ngOnInit() {
    if(this.itemId != null) {
      this.itemService.getItemById(this.itemId).subscribe(item=>{
        if(item != null){
          this.item = item
        }
      })
    }
  }

  async onSubmit(){
    this.loading = await this.loadingCtrl.create({
      backdropDismiss: false,
      spinner: 'lines'
    });
    await this.loading.present();
    try {
      const date: number = Date.now()
      if(!this.item.createAt){
        this.item.createAt = date
      }else{
        this.item.updateAt = date
      }
      if(this.coverImgURL){
        this.item.coverImgURL = await this.uploadImg(this.coverImgURL)
      }
      this.item.businessId = this.rolService.rol.businessId
      await this.itemService.setItem(this.item)
      await this.loading.dismiss();
      this.toast = await this.toastCtrl.create({
        message: `Se ha ${this.itemId ? 'actualizado': 'guardado'} la información con éxito`,
        duration: 3000
      });
      await this.toast.present();
      await this.modalCtrl.dismiss()
    } catch (error) {
      console.log(error)
      await this.loading.dismiss();
      this.toast = await this.toastCtrl.create({
        message: 'No se ha podido actualizar/crear el producto',
        color: 'warning',
        duration: 2000
      });
      await this.toast.present();
    }
  }

  async presentActionSheet() {
    if(this.platform.is("cordova")){
      const actionSheet = await this.actionSheetCtrl.create({
        header: 'Imagen del Producto',
        buttons: [
          {
            text: 'Tomar foto',
            icon: 'camera',
            handler: () => {
              this.takePhoto()
            }
          },
          {
            text: 'Abrir galería',
            icon: 'folder-open',
            handler: () => {
              this.takeImage()
            }
          }
        ]
      });
      await actionSheet.present();
    }else{
      const modal = await this.modalCtrl.create({
        component: WebImageComponent,
        componentProps: {
          maxWidth: 512,
          title: 'Imagen del Producto',
          aspectRatio: 2/1,
        }
      });
      await modal.present();
      const { data } = await modal.onWillDismiss();
      if(data?.croppedImage){
        this.coverImgURL = data?.croppedImage
      }
    }
  }

  async takePhoto(){
    try{
      if(await this.imagePicker.hasReadPermission()){
        const imageData = await Camera.getPhoto(this.cameraOptions)
        if(imageData[0]){
          const modal = await this.modalCtrl.create({
            component: WebImageComponent,
            componentProps: {
              maxWidth: 512,
              title: 'Imagen del Producto',
              aspectRatio: 2/1,
              imageBase64: 'data:image/jpeg;base64,'+imageData[0]
            }
          });
          await modal.present();
          const { data } = await modal.onWillDismiss();
          if(data?.croppedImage){
            this.coverImgURL = data?.croppedImage
          }
        }
      }else{
        await this.handlePermission()
      }
    }catch(error){
      const date: number = Date.now()
      this.toast = await this.toastCtrl.create({
        message: 'No se pudo tomar la foto',
        color: 'danger',
        duration: 2000
      });
      await this.toast.present();
    }
  }

  async takeImage(){
    try{
      if(await this.imagePicker.hasReadPermission()){
        const imageData = await this.imagePicker.getPictures(this.imagePickerOptions)
        if(imageData[0]){
          const modal = await this.modalCtrl.create({
            component: WebImageComponent,
            componentProps: {
              maxWidth: 512,
              title: 'Imagen del Producto',
              aspectRatio: 2/1,
              imageBase64: 'data:image/jpeg;base64,'+imageData[0]
            }
          });
          await modal.present();
          const { data } = await modal.onWillDismiss();
          if(data?.croppedImage){
            this.coverImgURL = data?.croppedImage
          }
        }
      }else{
        await this.handlePermission()
      }
    }catch(error){
      const date: number = Date.now()
      this.toast = await this.toastCtrl.create({
        message: 'No se pudo seleccionar la imagen',
        color: 'danger',
        duration: 2000
      });
      await this.toast.present();
    }
  }

  private async handlePermission(){
    this.toast = await this.toastCtrl.create({
      message: 'Se necesitan permisos de almacenamiento',
      duration: 4000,
      color: 'warning',
      buttons: [
        {
          side: 'end',
          icon: 'cog',
          text: 'Administrar',
          handler: async () => {
            await this.openNativeSettings.open('application_details')
          }
        }
      ]
    });
    return await this.toast.present();
  }

  async uploadImg(data_url:string):Promise<string>{
    try{
      const filePath = `assets/images/photos/items/${this.item.createAt}.jpeg`;
      const ref = this.storage.ref(filePath);
      const task = await ref.putString(data_url,'data_url',{
        contentType: 'image/jpeg',
      });
      return task.ref.getDownloadURL()
    }catch(error){
      const date: number = Date.now()
      this.toast = await this.toastCtrl.create({
        message: 'No se pudo cargar la imagen seleccionada',
        duration: 2000,
        color: 'danger'
      });
      await this.toast.present();
    }
  }

  durationChanged(e){
    this.item.duration = Number(e.detail.value)
  }

  validForm():boolean{
    if(this.item.name && this.item.description && (this.coverImgURL || this.item.coverImgURL) && this.item.price){
      return true 
    }
    return false
  }

}
